export const ISEXISTED='/api/isExisted/';
export const LOGIN = '/api/memberLogin';
export const GETAGREEMENT='/api/agreement/';
export const GETVERIFICATIONCODE='/api/verificationCode/';
export const REGISTER='/api/register';
export const FORGETPASSWORD='/api/forgetPassword';
export const SEARCHKW='/api/searchKW';
export const SEARCHAUID='/api/searchAuId';
// export const EXPORTCVS='http://1.14.16.201:8881/api/export/';
// export const EXPORTCVS2='http://1.14.16.201:8881/api/exportDetail/';
export const EXPORTCVS='https://affinityanalyst.com/api/export/';
export const EXPORTCVS2='https://affinityanalyst.com/api/exportDetail/';
export const SAVESEARCHMESSAGE='/api/saveSearchMessage';
export const GETJOBMANAGER='/api/getJobManager';
export const CANCELJOB='/api/cancelJob/';
export const DELETEJOB = '/api/removeJob/';
export const RESTARTJOB='/api/restart/';
export const GETJOBDETAIL ='/api/getJobDetail/';
export const ISPAID='/api/isPaid';
export const GETAUDIENCEMANAGER='/api/getAudienceManager';
export const GETSEARCHDETAIL='/api/getSearchDetail/';
export const UPDATESEARCHRESULT='/api/updateSearchResult';
export const SAVESEARCHRESULT='/api/saveSearchResult';
export const GETEXTENDBYAUDI='/api/getExtendByAudi/';
export const GETPAYMENTLIST='/api/paymentPackageList';
export const PAY='/api/paypal/pay';
// export const EXPORTDETAIL='http://1.14.16.201:8881/api/exportDetail/';
export const EXPORTDETAIL='https://affinityanalyst.com/api/exportDetail/';
export const GETAUDIENCEID='/api/getAudienceId';
export const SAVEAUDIENCEID='/api/saveAudienceId';
export const GETAUDIENCEIDLIST='/api/getAudienceIdList';
export const UPDATEPASSWORD ='/api/updatePassword';
export const IMPROVEPROFILE='/api/member/improveProfile';
export const MEMBERINFO='/api/memberInfo';
export const CONTACTUS='/api/saveContactUs';
export const GETTRANSACTION='/api/transactionList';
export const GETNOTICEMSG= '/api/getNoticeMsg';
export const UPDATEREADSTATUS='/api/updateReadStatus';
export const MEMBERSUBSCRIBEMSG='/api/memberSubscribeMsg';
export const DOWNLOADINVOICE='/api/downloadInvoiceBatch/';
export const UPDATEJOBTITLE = '/api/updateJobTitle';
export const SAVESEARCHMESSAGEBYGROUP='/api/saveSearchMessageByGroup';
export const GETNEWMESSAGECOUNT='/api/getUnreadJob';
export const GETARCHIVELIST='/api/getArchiveList';
export const UPDATEWINNERLIST='/api/updateSearchResultStatus';
export const GETWINNERLIST='/api/getWinnerList';
export const DELETEAUDIENCEID='/api/deleteAudienceId/';
export const GETAUDIENCELIST='/api/getAudienceList';
export const GETARCHIVEDETAIL='/api/getArchiveDetail';
export const UPDATEGROUPNAME='/api/updateJobOrGroupName';

export const READJOBMANGER='/api/readJobManger';
export const SAVECONTACTUS='/api/saveContactUs';
export const GETNOREADAUDIENCE='/api/getNoReadAudience';
export const UPDATENOREADAUDIENCE='/api/updateAudienceStatus';

export const GETBLOGLIST='/api/getBlogList';
export const GETJOBLIST='/api/getJobList';
export const GETBLOG='/api/getBlog/';
export const GETUSERMESSAGE='/api/getUserMessage';

export const GETTIPSLIST='/api/getTipsList';
// FB info
export const SAVEFBUSER='/api/saveFBUser';
export const GETFBUSER='/api/getFBUser';
export const ABANDONFBUSER='/api/abandonFBUser';
